<template>
    <div class="ReportSpam">
        <div class="selectType" v-show="showIndex==0">
            <div class="divclass2" @click="showIndex=1">该邮件为垃圾邮件
                <i class="iconfont  icon-page-right"></i>

            </div>
            <div class="divclass" @click="showIndex=2">该邮件无法正常显示
                <i class="iconfont  icon-page-right"></i>
            </div>
        </div>
        <div class="ReportClass" v-show="showIndex==1">
            <div class="divclass2">
                该邮件为垃圾邮件

            </div>
            <div class="xian"></div>
            <div class="higthShow">{{junkMailText}}</div>
            <div class="jiange"></div>
            <div class="switch">
                <li>
                    <span>该地址所有邮件移入垃圾箱</span>
                    <span class="spanClass">
                        <van-switch v-model="spamChecked" />
                    </span>
                </li>
                <li>
                    <span>将以上地址加入黑名单</span>
                    <span class="spanClass">
                        <van-switch v-model="blackChecked" />
                    </span>
                </li>
            </div>

        </div>
        <div class="DisplayAbnormality" v-show="showIndex==2">
            <div class="divclass2">
                该邮件为垃圾邮件

            </div>
            <div>
                <van-cell-group>
                    <van-field v-model="message" type="textarea" placeholder="请描述邮件的问题，孚盟MX工程师将尽快处理您的问题，同时保护您的数据隐私！" rows="6" autosize />
                </van-cell-group>
            </div>

        </div>
        <div v-if="showIndex!=0" class="footClass" @click="submit()">保存</div>

    </div>
</template>
<script>
import titleMixin from '@/mixin/title'
import { mapGetters } from 'vuex'
export default {
    name: 'ReportSpam',
    title: '举报',
    mixins: [titleMixin],
    data() {
        return {
            showIndex: 0,
            blackChecked: false,
            spamChecked: false,
            message: '',
            mIds: '',
            junkMailText: '',
            junkOwnerMailAccount: '',
            type: ''

        }
    },
    computed: {
        ...mapGetters('Mail', ['currentCtid'])
    },
    created() {
    },
    mounted() {
        this.pageInit()
    },
    methods: {
        pageInit() {
            if (this.$route.path == '/mail/ReportSpam') {
                let { mId, junkMailText, junkOwnerMailAccount, type } = this.$route.params
                this.mIds = mId
                this.junkMailText = junkMailText
                this.junkOwnerMailAccount = junkOwnerMailAccount
                this.type = type
                this.showIndex = 0
            }
        },
        submit() {
            if (this.showIndex == 1) { // 垃圾邮件
                this.mailsSpamSetPost()
            } else { // 无法正常显示
                this.DisplayAbnormality()
            }
        },
        async mailsSpamSetPost() { // 垃圾邮件
            let _this = this
            // let mId = _this.getIdStr(this.mIds)
            // 为下属操作才有ctid
            let params = {
                blackListFlag: _this.blackChecked,
                mId: this.mIds,
                type: this.type,
                fromAddress: this.junkMailText,
                mailAccount: this.junkOwnerMailAccount,
                moveAll: this.spamChecked
            }
            if (this.currentCtid != this.companie.ctId && this.currentCtid != '') {
                params.ctid = this.currentCtid
            } else {
                delete params.ctid
            }
            try {
                let url = this.Global.config.apiBaseURL + this.Global.api.Mail.mailsSpamSetPost
                let res = await this.axios.post(url, params)
                if (res.data.code.toString() == this.Global.config.RES_OK) {
                    this.$toast.success('举报成功')
                    this.$router.back()
                } else {
                    this.$toast.fail(res.data.msg)
                }
            } catch (error) {

            }
        },
        getIdStr(arry) {
            let str = ''
            for (let index = 0; index < arry.length; index++) {
                const element = arry[index]
                str += str + element + ','
            }
            return str.substring(0, str.length - 1)
        },
        async DisplayAbnormality() { // 无法正常显示
            if (this.message == '') {
                this.$toast.fail('描述邮件的问题必填')
                return
            }
            // let mIds = []
            // let fileIds = []
            // for (let index = 0; index < this.paramsData.length; index++) {
            //     const element = this.paramsData[index]
            //     mIds.push(element.mid)
            //     fileIds.push({
            //         'mailId': element.mid,
            //         'ctId': this.company.ctId,
            //         'cId': this.company.cId,
            //         'billId': element.mid,
            //         'custId': '',
            //         'fileName': element.subject + '.eml',
            //         'size': element.size == undefined ? 0 : element.size
            //         // "rawData": element.rawData == undefined ? "" : element.rawData
            //     })
            // }
            // let Parmars = {
            //     'moduleCode': 'EM001',
            //     'content': this.textarea,
            //     'caption': '举报乱码邮件',
            //     'category': '1',
            //     'custId': '',
            //     'priority': '1',
            //     'sourceId': 'pc',
            //     'mobile': '',
            //     'eMail': '',
            //     'fileIds': fileIds
            // }
            // this.$http.post(this.Global.config.apiBaseURL + this.Global.api.v2.workOrder_add, Parmars).then(function (res) {
            //     if (res.data.code.toString() == this.Global.config.RES_OK) {
            //     } else {
            //         this.$message.error(this.msg(res.data))
            //     }
            // }, function (res) {
            //     this.$message.error(this.$t(this.Global.config.errorTitle))
            //     this.loading = false
            // })
            let havingId = this.mIds.toString().split(',')
            let mIdsStr = []
            for (let index = 0; index < havingId.length; index++) {
                const element = havingId[index]
                mIdsStr.push(element)
            }
            // 报告乱码邮件
            let params = {
                mIds: mIdsStr,
                type: 'messy'
            }
            if (this.currentCtid != this.companie.ctId && this.currentCtid != '') {
                params.ctid = this.currentCtid
            } else {
                delete params.ctid
            }
            try {
                let url = this.Global.config.apiBaseURL + this.Global.api.Mail.ManyMaillistPut
                let res = await this.axios.post(url, params)
                if (res.data.code.toString() == this.Global.config.RES_OK) {
                    this.$toast.success('举报成功')
                    this.$router.back()
                } else {
                    this.$toast.fail(res.data.msg)
                }
            } catch (error) {

            }
        }

    },
    watch: {
        $route(to, from) {
            this.pageInit()
        }
    }
}
</script>
<style lang="less" scoped>
@import "./zh-cn.less";
@import "./en.less";
</style>
